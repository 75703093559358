import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { identifyUser, resetUser, trackEvent } from '@/common/analytics.ts';
import { config } from '@/config/config.ts';
import { queryClient } from '@/config/query-client.ts';
import type { RegistrationFormData } from '@/features/auth/types/registration.schema.ts';
import {
  UserSchema,
  type UserType,
} from '@/features/auth/types/user.schema.ts';

export const getAuthUser = () => {
  return queryClient.getQueryData<UserType>(['authUser']);
};

export const useUser = () => {
  const authUser = getAuthUser();
  return useQuery<UserType>({
    enabled: !authUser,
    initialData: authUser,
    queryFn: async () => {
      return await fetchUser();
    },
    queryKey: ['authUser'],
  });
};

export const fetchUser = async (): Promise<UserType> => {
  const result = await axios.get('/api/v1/auth/me');
  const userData = UserSchema.parse(result.data);
  if (userData.id) {
    identifyUser(userData.id, {
      email: userData.email,
      name: userData.username,
    });
  }
  return userData;
};

export const useAuth = () => {
  const loginMutation = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) => {
      return axios.post(config.apiUrl + 'auth/login', {
        email,
        password,
      });
    },
    onSuccess: async () => {
      trackEvent('user_logged_in', {
        $set: {
          login_type: 'login_form',
        },
      });
      await fetchUser();
    },
  });

  const oauthMutation = useMutation({
    mutationFn: (provider: string) => {
      return axios.get(config.apiUrl + `auth/${provider}/authorize`);
    },
    onSuccess: () => {
      trackEvent('user_requested_oauth_url');
    },
  });

  const logoutMutation = useMutation({
    mutationFn: () => {
      return axios.delete(config.apiUrl + 'auth/logout');
    },
    onSuccess: () => {
      trackEvent('user_logged_out');
      resetUser();
      // await fetchUser();
    },
  });

  const registerMutation = useMutation({
    mutationFn: (data: RegistrationFormData) => {
      return axios.post(`${config.apiUrl}auth/register`, data);
    },
    onSuccess: async () => {
      trackEvent('user_registered');
      await fetchUser();
    },
  });

  return {
    loginMutation,
    logoutMutation,
    oauthMutation,
    registerMutation,
  };
};
