import { z } from 'zod';

export const UserSchema = z.object({
  email: z.string().email(),
  id: z.string(),
  oauth_accounts: z.array(
    z.object({
      account_email: z.string().email(),
      oauth_name: z.string(),
    }),
  ),
  username: z.string(),
});

export type UserType = z.infer<typeof UserSchema>;
