import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://f8d513b45e3f46849444dd481206eb2d@sentry-relay.verbal.ink/65',
  enabled: import.meta.env.ENVIRONMENT !== 'development',
  environment: import.meta.env.ENVIRONMENT as string,
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  profilesSampleRate: 1,
  release: import.meta.env.GIT_RELEASE as string,
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  tracePropagationTargets: [
    'localhost',
    '127.0.0.1',
    /^https:\/\/app.*\.verbal\.ink\/api/,
  ],
  tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
});
