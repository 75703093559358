import { extendTheme } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface PalettePrimaryOverrides {
    950: true;
    1000: true;
  }
  interface PaletteNeutralOverrides {
    950: true;
  }
  interface PaletteDangerOverrides {
    950: true;
  }
  interface PaletteSuccessOverrides {
    950: true;
  }
  interface PaletteWarningOverrides {
    950: true;
  }
}

const danger = {
  50: 'rgba(254 242 241 / 1)',
  100: 'rgba(254 226 225 / 1)',
  200: 'rgba(255 202 199 / 1)',
  300: 'rgba(253 169 165 / 1)',
  400: 'rgba(249 120 113 / 1)',
  500: 'rgba(242 92 84 / 1)', // Main
  600: 'rgba(223 47 38 / 1)',
  700: 'rgba(188 34 26 / 1)',
  800: 'rgba(153 32 26 / 1)',
  900: 'rgba(130 34 28 / 1)',
  950: 'rgba(68 12 9 / 1)',
};

const neutral = {
  50: 'rgba(247 247 248 / 1)',
  100: 'rgba(239 239 241 / 1)',
  200: 'rgba(217 217 222 / 1)',
  300: 'rgba(184 185 193 / 1)', // Light
  400: 'rgba(146 148 160 / 1)',
  500: 'rgba(114 116 131 / 1)',
  600: 'rgba(92 93 106 / 1)',
  700: 'rgba(76 77 87 / 1)',
  800: 'rgba(64 64 74 / 1)',
  900: 'rgba(57 57 65 / 1)',
  950: 'rgba(24 24 27 / 1)',
};

const primary = {
  50: 'rgba(238 230 255 / 1)',
  100: 'rgba(227 209 255 / 1)',
  200: 'rgba(203 168 255 / 1)',
  300: 'rgba(177 117 255 / 1)',
  400: 'rgba(168 61 255 / 1)',
  500: 'rgba(173 20 255 / 1)',
  600: 'rgba(170 0 255 / 1)',
  700: 'rgba(170 0 255 / 1)',
  800: 'rgba(153 0 230 / 1)',
  900: 'rgba(125 0 179 / 1)',
  950: 'rgba(112 60 211 / 1)',
  1000: 'rgba(22 0 31 / 1)',
};

const success = {
  50: 'rgba(238 252 244 / 1)',
  100: 'rgba(211 248 226 / 1)',
  200: 'rgba(168 240 202 / 1)',
  300: 'rgba(146 224 179 / 1)', // Main
  400: 'rgba(59 206 140 / 1)',
  500: 'rgba(23 176 115 / 1)',
  600: 'rgba(11 142 92 / 1)',
  700: 'rgba(9 113 75 / 1)',
  800: 'rgba(10 92 63 / 1)',
  900: 'rgba(9 73 52 / 1)',
  950: 'rgba(4 42 30 / 1)',
  solidActiveBg: 'var(--vl-palette-success-600)',
  solidBg: 'var(--vl-palette-success-400)',
  solidHoverBg: 'var(--vl-palette-success-500)',
};

const warning = {
  50: 'rgba(254 249 236 / 1)',
  100: 'rgba(251 238 203 / 1)',
  200: 'rgba(246 220 146 / 1)',
  300: 'rgba(243 201 104 / 1)', // Main
  400: 'rgba(238 171 47 / 1)',
  500: 'rgba(231 138 24 / 1)',
  600: 'rgba(205 106 19 / 1)',
  700: 'rgba(170 72 19 / 1)',
  800: 'rgba(136 58 22 / 1)',
  900: 'rgba(112 46 21 / 1)',
  950: 'rgba(65 23 7 / 1)',
};

export const theme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        danger,
        divider: 'var(--vl-palette-neutral-300)',
        neutral,
        primary,
        success,
        warning,
      },
    },
    light: {
      palette: {
        danger,
        divider: 'var(--vl-palette-neutral-300)',
        neutral,
        primary,
        success,
        text: {
          primary: 'var(--vl-palette-neutral-900)',
        },
        warning,
      },
    },
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: {
          styles: {},
        },
      },
    },
  },
  cssVarPrefix: 'vl',
  focus: {
    default: {
      outlineWidth: '3px',
    },
  },
  fontFamily: {
    body: "'Inter Variable', var(--vl-fontFamily-fallback)",
    display: "'Inter Variable', var(--vl-fontFamily-fallback)",
  },
});

export default theme;
