import {
  createRoute,
  lazyRouteComponent,
  redirect,
} from '@tanstack/react-router';
import { z } from 'zod';

import { fetchUser } from '@/features/auth/hooks/use-auth.ts';
import { rootRoute } from '@/routes/root.route.tsx';

const AuthLayout = lazyRouteComponent(
  () => import('../layouts/auth.layout.tsx'),
  'AuthLayout',
);
const Login = lazyRouteComponent(
  () => import('./login.route.tsx'),
  'LoginRoute',
);
const SignUp = lazyRouteComponent(
  () => import('./signup.route.tsx'),
  'SignupRoute',
);

const authRoute = createRoute({
  beforeLoad: async () => {
    let isUserLoggedIn = false;
    try {
      await fetchUser();
      isUserLoggedIn = true;
    } catch {
      isUserLoggedIn = false;
    }
    if (isUserLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        to: '/',
      });
    }
  },
  component: AuthLayout,
  getParentRoute: () => rootRoute,
  id: 'auth',
});

const loginRoute = createRoute({
  component: Login,
  getParentRoute: () => authRoute,
  path: 'login',
  validateSearch: z.object({
    // eslint-disable-next-line unicorn/prefer-top-level-await
    redirect: z.string().catch('/'),
  }),
});

const signUpRoute = createRoute({
  component: SignUp,
  getParentRoute: () => authRoute,
  path: 'signup',
});

export { authRoute, loginRoute, signUpRoute };
