import {
  createRoute,
  lazyRouteComponent,
  redirect,
} from '@tanstack/react-router';

import { getPathFromUrl } from '@/common/url.ts';

import { fetchUser } from '../features/auth/hooks/use-auth.ts';
import { rootRoute } from './root.route.tsx';

const MainLayout = lazyRouteComponent(
  () => import('@/features/app/layouts/app.layout.tsx'),
  'AppLayout',
);
const Dashboard = lazyRouteComponent(
  () => import('../features/media/routes/dashboard.route.tsx'),
  'DashboardRoute',
);

const Transcription = lazyRouteComponent(
  () => import('../features/transcription/routes/transcription.route.tsx'),
  'TranscriptionRoute',
);

const Billing = lazyRouteComponent(
  () => import('../features/billing/routes/billing.route.tsx'),
  'BillingRoute',
);

const Buy = lazyRouteComponent(
  () => import('../features/billing/routes/buy.route.tsx'),
  'BuyRoute',
);
const PaymentSuccess = lazyRouteComponent(
  () => import('../features/billing/routes/payment-success.route.tsx'),
  'PaymentSuccessRoute',
);
const Payments = lazyRouteComponent(
  () => import('../features/billing/routes/payments.route.tsx'),
  'PaymentsRoute',
);
const Transactions = lazyRouteComponent(
  () => import('../features/billing/routes/transactions.route.tsx'),
  'TransactionsRoute',
);

const Account = lazyRouteComponent(
  () => import('../features/auth/routes/account.route.tsx'),
  'AccountRoute',
);
const ApiKeys = lazyRouteComponent(
  () => import('../features/api/routes/api-keys.route.tsx'),
  'ApiKeysRoute',
);

const protectedRoutes = createRoute({
  beforeLoad: async () => {
    try {
      await fetchUser();
    } catch {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw redirect({
        search: {
          redirect: getPathFromUrl(window.location.href) ?? '/',
        },
        to: '/login',
      });
    }
  },
  component: MainLayout,
  getParentRoute: () => rootRoute,
  id: 'protected',
});

const dashboardRoute = createRoute({
  component: Dashboard,
  getParentRoute: () => protectedRoutes,
  path: '/',
});
const accountRoute = createRoute({
  component: Account,
  getParentRoute: () => protectedRoutes,
  path: '/account',
});
const apiKeysRoute = createRoute({
  component: ApiKeys,
  getParentRoute: () => protectedRoutes,
  path: '/account/api-keys',
});
const transcriptionRoute = createRoute({
  component: Transcription,
  getParentRoute: () => protectedRoutes,
  path: '/transcription/$mediaId',
});
const billingRoute = createRoute({
  component: Billing,
  getParentRoute: () => protectedRoutes,
  path: '/billing',
});
const buyRoute = createRoute({
  component: Buy,
  getParentRoute: () => billingRoute,
  path: 'buy',
});
const transactionsRoute = createRoute({
  component: Transactions,
  getParentRoute: () => billingRoute,
  path: 'transactions',
});

const paymentSuccessRoute = createRoute({
  component: PaymentSuccess,
  getParentRoute: () => billingRoute,
  path: 'success',
});
const paymentsRoute = createRoute({
  component: Payments,
  getParentRoute: () => billingRoute,
  path: 'payments',
});

 
export {
  accountRoute,
  apiKeysRoute,
  billingRoute,
  buyRoute,
  dashboardRoute,
  paymentsRoute,
  paymentSuccessRoute,
  protectedRoutes,
  transactionsRoute,
  transcriptionRoute,
};
