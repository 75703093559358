const ENVIRONMENT = import.meta.env.ENVIRONMENT as string;
const DEFAULT_LANGUAGE = 'en';
const PROD_API_URL = 'https://app.verbal.ink/api/v1/';
const API_URL = (import.meta.env.VITE_API_URL || PROD_API_URL) as string;

export const config = {
  apiUrl: API_URL,
  defaultLanguage: DEFAULT_LANGUAGE,
  env: ENVIRONMENT,
  posthogAutoCapture: true,
  posthogHost: import.meta.env.POSTHOG_HOST as string,
  posthogKey: import.meta.env.POSTHOG_KEY as string,
};
