import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';

import { config } from '@/config/config.ts';

interface AxiosRequestConfigWithRetry extends AxiosRequestConfig {
  _retry?: boolean;
}

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfigWithRetry | undefined =
      error.config;
    if (
      (error.response?.status === 401 || error.response?.status === 422) &&
      originalRequest &&
      !originalRequest.url?.includes('auth/refresh') &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        await axios.post(config.apiUrl + 'auth/refresh');
        return axios(originalRequest);
      } catch {
        /* empty */
      }
    }
    throw error;
  },
);
