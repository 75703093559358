import posthog, { CaptureOptions, Properties } from 'posthog-js';

export function isPosthogLoaded(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return posthog?.__loaded;
}

/**
 * Track an event in PostHog.
 * @see https://posthog.com/docs/data/events
 * @see https://posthog.com/docs/libraries/js
 */
export function trackEvent(
  eventName: string,
  properties?: null | Properties,
  options?: CaptureOptions,
) {
  if (isPosthogLoaded()) {
    posthog.capture(eventName, properties, options);
  }
}

/**
 * Identify a user by their unique ID.
 * This is useful when you want to associate events with a specific user.
 */
export function identifyUser(
  userId: string,
  userPropertiesToSet?: Properties,
  userPropertiesToSetOnce?: Properties,
) {
  if (isPosthogLoaded()) {
    posthog.identify(userId, userPropertiesToSet, userPropertiesToSetOnce);
  }
}

/**
 * Reset the user's identity and clear all user data.
 * This is useful when a user logs out.
 */
export function resetUser() {
  if (isPosthogLoaded()) {
    posthog.reset();
  }
}
