import { createRoute, lazyRouteComponent } from '@tanstack/react-router';

import { rootRoute } from './root.route.tsx';

const Api = lazyRouteComponent(
  () => import('../features/docs/routes/api.route.tsx'),
  'ApiRoute',
);

const apiRoute = createRoute({
  component: Api,
  getParentRoute: () => rootRoute,
  path: '/docs/api',
});

export { apiRoute };
