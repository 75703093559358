import Box from '@mui/joy/Box';
import {
  createRootRoute,
  Outlet,
  ScrollRestoration,
} from '@tanstack/react-router';
import { Suspense } from 'react';

import { LoadingIndicator } from '@/features/app/components/loading-indicator.tsx';
// import { TanStackRouterDevtools } from '@/routes/router.ts';

const FallBackComponent = () => (
  <Box sx={{ maxWidth: 1280, mx: 'auto', p: 4 }}>
    <LoadingIndicator />
  </Box>
);

export const rootRoute = createRootRoute({
  component: () => (
    <Suspense fallback={<FallBackComponent />}>
      <ScrollRestoration />
      <Outlet />
      {/*<TanStackRouterDevtools />*/}
    </Suspense>
  ),
});
